import { useState } from "react"

export default function FocusMode({isFocused, setIsFocused, focusText, setFocusText}) {



    const focusMode = (event) => {

        const taskCardsCollection = document.getElementsByClassName('resize-drag')
        const taskCards = [...taskCardsCollection]

        if(isFocused) {
            setIsFocused(false)
            taskCards.forEach((card) => {
                card.classList.remove('focusMode')                
            })
            setFocusText(' Focus Mode')
        }
        else {
            setIsFocused(true)
            taskCards.forEach((card) => {
                card.classList.add('focusMode')
            })
            setFocusText('')
        }
    }

    return (
        <div id="focusMode">
            <button onClick={focusMode}>
                <span className="material-symbols-outlined focus">mindfulness</span>
                <span> {focusText}</span>
            </button>
        </div>
    )

}