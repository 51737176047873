import { addTask, getProjects, condenseTask } from "../modules/todo";
import Swal from 'sweetalert2';

export default function AddTask({api, tasks, setTasks}) {
        
    const submitForm = async (task) => {
            
        const addedTask = await addTask(task , api)

        if (addedTask) {

            const newTaskList = [...tasks]
            newTaskList.push(condenseTask(addedTask, tasks.length))
            const mapZIndex = newTaskList.map((task, index) => ({
                ...task,
                zIndex: index,
            }));
            setTasks(mapZIndex)

            Swal.fire({
                title: 'Task added',
                color: 'white',
                icon: 'info',
                confirmButtonColor: 'darkslategrey',
                confirmButtonText: 'Close',
                background: 'darkgoldenrod',
                iconColor: 'darkslategrey',
                timer: 2000
            })
        }
    }

    const taskForm = async () => {

        const projects = await getProjects(api);

        let options;
        
        {projects.map((project, index) => (
            options += `<option key='${index}' id='${project.id}' className="projects" value='${project.name}' />`
        ))}

        const task = await Swal.fire({
            title: "Add a task",
            html: `
                <label for="task">Task Name: </label>
                <input id="task" class="swal2-input">
                <br>
                <label for"description">Description: </label>
                <textarea id="description" class="swal2-textarea"></textarea>
                <br>
                <label for="due">Due: </label>
                <input id="due" class="swal2-input" placeholder="this Friday">
                <br>
                <label for="labels">Labels: </label>
                <input id="labels" class="swal2-input" placeholder="label1, label2, label3">
                <br>
                <input id="project" list="projects" name="projects" />
                <datalist id="projects">
                    ${options}
                </datalist>
            `,
            background: 'darkslategray',
            color: 'goldenrod',
            showCancelButton: true,
            confirmButtonColor: "darkgoldenrod",
            confirmButtonText: "Add Task",
            focusConfirm: false,
            preConfirm: () => {
                const task = document.getElementById("task").value
                if (!task) {
                    Swal.showValidationMessage(`Please enter a task name`)
                }
                const projectName = document.getElementById('project').value
                let project = projects.find((project) => project.name === projectName)                
                if (project === undefined) {
                    // This is the project ID for the Inbox
                    project = {id: 2201565707}
                }

                let labels = document.getElementById("labels").value
                if (labels.indexOf(',') > -1) {
                    document.getElementById("labels").value.split(',')
                }
                else {
                    labels = [labels]
                }

                return {
                    content: task,
                    description: document.getElementById("description").value,
                    dueString: document.getElementById("due").value,
                    labels: labels,
                    projectId: project.id
                };
            }
        });
        if(task.isConfirmed){
            submitForm(task.value)
        }
    }

    return (
        <div id="addTask">
            <button onClick={taskForm} >Add Task</button>
        </div>
    )

}
