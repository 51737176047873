import {useState, useEffect} from 'react';
import ResizeDrag from './components/ResizeDrag';
import styles from './css/styles.css';
import GetTasks from './components/GetTasks';
import AddTask from './components/AddTask';
import FocusMode from './components/FocusMode';
import Timer from './components/Timer';
import { TodoistApi } from "@doist/todoist-api-typescript";
import Swal from 'sweetalert2';
import OrganizeButton from './components/OrganizeButton';
import Organize from './components/Organize';

function App() {

  const [tasks, setTasks] = useState([]);
  const [api, setApi] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [focusText, setFocusText] = useState(' Focus Mode')
  
  function enterKey() {
    Swal.fire({
      title: "Enter Key",
      input: "text",
      background: 'darkslategray',
      color: 'goldenrod',
      showCancelButton: true,
      confirmButtonColor: "darkgoldenrod",
      confirmButtonText: "Add Key",
      focusConfirm: false
    })
    .then(userKey => {
      if (userKey.isConfirmed) {
        const todoistApi = new TodoistApi(userKey.value)
        if (todoistApi.authToken === ''){
          Swal.fire({
            title: "Invalid Key",
            text: "Try a different key",
            background: 'darkslategray',
            color: 'goldenrod',
            showCloseButton: true,
            showConfirmButton: false
          })
        }
        else {
          setApi(todoistApi)
        }
      }
    })
  }
  
  if (api == null) {
    return (
      <div className='App'>
        <h1>MyTasks</h1>
        <p>You must enter a key to enter the site.</p>
        <button onClick={enterKey}>Enter Key</button>
      </div>
    )

  }
  else {
    if (!isFocused) {
      return (
        <div className='App'>
          <header>
            <h1>MyTasks</h1>
            <div id="tasks">
              <AddTask api={api} tasks={tasks} setTasks={setTasks}/>
              <GetTasks api={api} tasks={tasks} setTasks={setTasks} />
              {/* <OrganizeButton api={api} tasks={tasks} setTasks={setTasks} /> */}
              <FocusMode isFocused={isFocused} setIsFocused={setIsFocused} focusText={focusText} setFocusText={setFocusText}  />
            </div>
          </header>
          <main>
            {/* <Organize api={api} tasks={tasks} setTasks={setTasks} /> */}
            <ResizeDrag api={api} tasks={tasks} setTasks={setTasks} />        
          </main>
        </div>
      )
    }
    else {
      return (
        <div className='App'>
          <header>
            <h1>MyTasks</h1>
            <div id="tasks">
              <Timer />
              <FocusMode isFocused={isFocused} setIsFocused={setIsFocused} focusText={focusText} setFocusText={setFocusText}  />
            </div>
          </header>
          <main>
            <ResizeDrag api={api} tasks={tasks} setTasks={setTasks} />        
          </main>
        </div>
      )
    }
  }

}

export default App;
