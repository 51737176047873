import { RandomBannerQuote } from './RandomBannerQuote';
import {completeTask, reopenTask, getTask, updateTask, deleteTask} from '../modules/todo';
import Swal from 'sweetalert2';

const interact = require('interactjs');

export default function ResizeDrag({tasks, setTasks, api}) {

  const handlePosition = (event) => {
    let [topTask] = tasks.filter((task) =>  task.id === event.currentTarget.id)
    let filteredTasks =  tasks.filter((task) => task.id !== topTask.id)
    filteredTasks.push(topTask)
    setTasks(filteredTasks)
  }

  function dragMoveListener (event) {
    var target = event.target
    // keep the dragged position in the data-x/data-y attributes
    var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
    var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;   
    
    // translate the element
    target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
    
    // update the posiion attributes
    target.setAttribute('data-x', x);
    target.setAttribute('data-y', y);
  }
    
    // this function is used later
    window.dragMoveListener = dragMoveListener;

    interact('.resize-drag')
    .resizable({
        // resize from all edges and corners
        edges: { left: true, right: true, bottom: true, top: true },

        listeners: {
        move (event) {
            var target = event.target
            var x = (parseFloat(target.getAttribute('data-x')) || 0)
            var y = (parseFloat(target.getAttribute('data-y')) || 0)

            // update the element's style
            target.style.width = event.rect.width + 'px'
            target.style.height = event.rect.height + 'px'
            target.style.zIndex = 

            // translate when resizing from top or left edges
            x += event.deltaRect.left
            y += event.deltaRect.top

            target.style.transform = 'translate(' + x + 'px,' + y + 'px)'

            target.setAttribute('data-x', x)
            target.setAttribute('data-y', y)
        }
        },
        modifiers: [
        // keep the edges inside the parent
        interact.modifiers.restrictEdges({
            outer: 'parent'
        }),

        // minimum size
        interact.modifiers.restrictSize({
            min: { width: 100, height: 50 }
        })
        ],

        inertia: true
    })
    .draggable({
        // enable inertial throwing
        inertia: true,
        // keep the element within the area of it's parent
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true
          })
        ],
        // enable autoScroll
        autoScroll: true,
    
        listeners: {
          
          // call this function on every dragstart event
          start: handlePosition,

          // call this function on every dragmove event
          move: dragMoveListener,
    
          // call this function on every dragend event
          end (event) {

          }
        }
      })

      const closeTaskCard = (e) => {
        e.stopPropagation();
        const id = e.target.parentNode.parentNode.id
        setTasks(prevTasks => prevTasks.filter((task) => task.id !== id))
      }
      const handleDeleteTask = (e) => {
        e.stopPropagation();
        const id = e.target.parentNode.parentNode.id
        deleteTask(id, api)
        setTasks(prevTasks => prevTasks.filter((task) => task.id !== id))
      }

      const markComplete = (e) => {
        e.stopPropagation();
        const taskCard = e.target.parentNode.parentNode;
        if(taskCard.classList.contains('complete')) {
            e.target.innerHTML = 'check_box_outline_blank';
            taskCard.classList.remove('complete');
            reopenTask(taskCard.getAttribute('id'), api);
        }
        else {
            e.target.innerHTML = 'check_box';
            taskCard.classList.add('complete');
            completeTask(taskCard.getAttribute('id'), api);
        }
      }

      const changeLabel = async (e) => {
        e.stopPropagation();
        const taskCard = e.target.parentNode.parentNode
        const labelsOutput = e.target.parentNode.children[1]

        await Swal.fire({
          title: "Edit labels",
          input: "text",
          text: "Use commas to add more than one label",
          background: 'darkslategray',
          color: 'goldenrod',
          inputValue: labelsOutput.innerHTML,
          inputPlaceholder: "label1, label2",
          showCancelButton: true,
          inputValidator: async (value) => {
            let labels = value;

            if (labels.indexOf(',') > -1) {
                labels = labels.split(',')
            }
            else {
                labels = [labels]
            }
            const task = await updateTask(taskCard.getAttribute('id'), {labels: labels}, api)
            labelsOutput.innerHTML = ` ${task.labels.join(', ')}`
          }
        });
      }

      const changeDueDate = async (e) => {

        e.stopPropagation();
        const taskCard = e.target.parentNode.parentNode
        const dueDateOutput = e.target.parentNode.children[1]

        await Swal.fire({
          title: "Edit the due date",
          input: "text",
          text: "Use natural language for the date. Leave empty for no date.",
          background: 'darkslategray',
          color: 'goldenrod',
          inputPlaceholder: "this Friday",
          showCancelButton: true,
          inputValidator: async (value) => {
            if (!value) {
              const task = await updateTask(taskCard.getAttribute('id'), {dueString: value}, api)
              dueDateOutput.innerHTML = ` No due date`
            }
            else {
                const task = await updateTask(taskCard.getAttribute('id'), {dueString: value}, api)
                dueDateOutput.innerHTML = ` ${task.due.date}`
            }
          }
        });
      }

      const changeTaskName = async (e) => {

        e.stopPropagation()
        const taskCard = e.target.parentNode.parentNode
        const output = e.target.parentNode.children[1]

        await Swal.fire({
          title: "Edit the task name",
          input: "text",
          inputValue: output.innerHTML,
          background: 'darkslategray',
          color: 'goldenrod',
          showCancelButton: true,
          preConfirm: (value) => {
            if (!value || value.trim() === "") {
              Swal.showValidationMessage("Please enter a task name");
            }
          },
          inputValidator: async (value) => {
            const task = await updateTask(taskCard.getAttribute('id'), {content: value}, api)
            output.innerHTML = ` ${task.content}`
          }
        });
      }

      const changeDescription = async (e) => {

        e.stopPropagation()
        const taskCard = e.target.parentNode
        const output = e.target.parentNode.children[1]
        console.log(taskCard)

        await Swal.fire({
          title: "Edit the description",
          input: "text",
          inputValue: output.innerHTML,
          background: 'darkslategray',
          color: 'goldenrod',
          showCancelButton: true,
          inputValidator: async (value) => {
            if (!value) {
              const task = await updateTask(taskCard.getAttribute('id'), {description: value}, api)
              output.innerHTML = `add description`
            }
            else {
                const task = await updateTask(taskCard.getAttribute('id'), {description: value}, api)
                output.innerHTML = ` ${task.description}`
            }
          }
        });
      }


      if (tasks.length === 0) {
        return (
            <>
                <RandomBannerQuote />
            </>
        )
    }
    else {
        return (
            <>
                {tasks.map((task, index) => (
                    <div key={task.id} id={task.id} className='resize-drag' style={{ zIndex: index }} onClick={handlePosition}>
                        <h3 className='taskName' >
                          <span className='material-symbols-outlined checkbox' onClick={markComplete}>check_box_outline_blank</span> 
                          <span className="name" onClick={changeTaskName}>{task.name}</span>
                          <span className='material-symbols-outlined close' onClick={closeTaskCard} >close</span>
                        </h3>
                        <p className='taskDescription' onClick={changeDescription}>{task.description}</p>
                        <a href={task.url} target='_blank'>View Task</a>
                        <p className='dueDate'>
                            <span onClick={changeDueDate} className='material-symbols-outlined calendar'>edit_calendar</span>
                            <span> {task.due}</span>
                        </p>
                        <p className='labels'>
                            <span onClick={changeLabel} className='material-symbols-outlined addLabel'>label</span>
                            <span>{task.labels.join(', ')}</span>
                            <span className='material-symbols-outlined delete' onClick={handleDeleteTask}>delete</span>
                        </p>
                    </div>
                ))}
            </>
        )
    }


}