export function todo(setTasks, filter, api) {

    setTasks([])

    api.getTasks({"filter":filter.query})
        .then((tasks) => {
            const condensedTasks = [];
            tasks.forEach((task, index) => {
                condensedTasks.push(condenseTask(task, index));
            })

            setTasks(condensedTasks)

        })
        .catch((error) => {
            console.log(error)
        });

}

export function condenseTask(task, index) {

    let due;
    if(!task.due) {
            due = 'No due date'
    }
    else {
        due = task.due.date
    }

    return  {
                id: task.id,
                name: task.content,
                description: task.description,
                url: task.url,
                labels: task.labels,
                due: due,
                zIndex: index
            }    
}

export async function addTask (task, api) {
    return await api.addTask(task)
    .then((addedTask) => {return addedTask})
    .catch((error) => {
        console.log(error)
    })
}
export async function getTask (id, api) {
    return await api.getTask(id)
    .then((task) => {return task})
    .catch((error) => {return false})
}

export async function updateTask (id, changes, api) {
    return await api.updateTask(id, changes)
        .then((task) => {return task})
        .catch((error) => {return false})
}
export async function deleteTask (id, api) {
    return await  api.deleteTask(id)
        .then((isSuccess) => console.log(isSuccess))
        .catch((error) => console.log(error))
}
export async function completeTask (id, api) {
    return await api.closeTask(id)
        .then((isSuccess) => {return isSuccess})
        .catch((error) => console.log(error))
}
export async function reopenTask(id, api) {
    return api.reopenTask(id)
        .then((isSuccess) => console.log(isSuccess))
        .catch((error) => console.log(error))
}
export async function getProjects(api){
    return await api.getProjects()
        .then((projects) => {
            return projects
        })
        .catch((error) => console.log(error))
}

