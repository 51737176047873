export function RandomBannerQuote() {
    const banners = [
        './air-balloon.gif',
        './cat.gif',
        './chair-lift.gif',
        './cloud.gif',
        './leaf.gif',
        './pillow.gif',
        './snow.gif'
    ];
    const quotes = [
        'You can be in only one place at a time. Right now, you are here and nowhere else.',
        'There are thousands of thoughts you are holding on to about other times and other places. Gather them all and blow them away gently with a long exhale.',
        'Reset. Remember every new day will have some peaks and some valleys. Stop and look around you. Breathe in. Breathe out.',
        'Take in this air around you and then release it. It is only here for a brief moment.',
        'Do whatever it takes to not fool yourself into thinking something is true that is not, or into thinking something is not true that is.',
        'The good thing about science is that it is true whether or not you believe in it.',
        'Your title and your academic pedigree is irrelevant when establishing what is objectively true in the world. It is a tacid recognition that if you have a great idea, that idea can come from any body at any time.'
    ];

    const randomBanner = Math.floor(Math.random() * banners.length);
    const randomQuote = Math.floor(Math.random() * quotes.length);

    return (
        <div id="banner">
            <p>{quotes[randomQuote]}</p>
            <img src={banners[randomBanner]}  alt="banner" />
        </div>
    );

}
