import { useState } from "react";

export default function Timer() {
    
    const [seconds, setSeconds] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [hours, setHours] = useState(0)
    let timeRemaining = 0;
    let timerInterval = null   // To be able to set and clear the interval.
    const [mediaButtons, setMediaButtons] = useState([
        {
            name: "play",
            displayValue: "none",
            icon: "play_circle"
        },
        {
            name: "pause",
            displayValue: "none",
            icon: "pause_circle"
        },
        {
            name: "stop",
            displayValue: "none",
            icon: "stop"
        }
    ])

    function formatTime(time) {
        let seconds = time % 60;
        let minutes = (time - seconds) / 60 % 60;
        let hours = (time - (minutes * 60 + seconds)) / 60 / 60;
    
        if (hours == 0) {
            if (seconds < 10) { seconds = `0${seconds}`; }
            return `${minutes}:${seconds}`;
        }
        else {
            if (seconds < 10) { seconds = `0${seconds}`; }
            if (minutes < 10) { minutes = `0${minutes}`; }
            return `${hours}:${minutes}:${seconds}`;
        }
    }


    const startCountdown = () => {
        timerInterval = setInterval(() => {
            if (timeRemaining == null || timeRemaining == 0) { 
               stopCountdown()
            }
            else if (timeRemaining > 0) {
                if (timeRemaining == 60) {
                    controlAudio('play')
                }                
                else if (timeRemaining == 10) {
                    controlAudio('play')
                }
                timeRemaining += -1
                document.getElementById('countdown').innerHTML = formatTime(timeRemaining)
            }
        }, 1000)
    }

    function formatTime(time) {

        let seconds = time % 60;
        let minutes = (time - seconds) / 60 % 60;
        let hours = (time - (minutes * 60 + seconds)) / 60 / 60;
    
        if (hours == 0) {
            if (seconds < 10) { seconds = `0${seconds}`; }
            return `${minutes}:${seconds}`;
        }
        else {
            if (seconds < 10) { seconds = `0${seconds}`; }
            if (minutes < 10) { minutes = `0${minutes}`; }
            return `${hours}:${minutes}:${seconds}`;
        }
    }

    const handleMediaClick = (e) => {
        const id = e.target.id
        switch(id) {
            case "play":
              resumeCountdown()
              break;
            case "pause":
              pauseCountdown()
              break;
            default:
              stopCountdown()
        }
    }
    const controlAudio = (input) => {
        const audio = document.getElementById('audio')
        switch(input) {
            case 'play':
              audio.load()
              audio.play()
              break;
            default:
                audio.pause()
          }
    }

    // Stop the timer
    function stopCountdown() {   
        timeRemaining = 0 // Remove the time left
        clearInterval(timerInterval); // Clear the timerInterval variable.
        controlAudio('stop');
        document.getElementById("countdown").style.display = "none";
        document.getElementById("pause").style.display = "none";
        document.getElementById("stop").style.display = "none";
        document.getElementById("enterTime").style.display = "inline-block";
    }
    
    // Pause the timer
    const pauseCountdown = () => {
        clearInterval(timerInterval); // Clear the timerInterval variable.
        document.getElementById("focusMode").style.display = "block";
        document.getElementById("pause").style.display = "none";
        document.getElementById("play").style.display = "inline-block";
    }

    // // Restart the timer
    // function restartCountdown() {
    //     stopCountdown();
    //     startCountdown(originalValue);
    // }

    // Resume the timer
    function resumeCountdown() {
        document.getElementById("focusMode").style.display = "none";
        startCountdown(timeRemaining);
        document.getElementById("play").style.display = "none";
        document.getElementById("pause").style.display = "inline-block";
        document.getElementById("stop").style.display = "inline-block";
        // document.getElementById("restart").style.display = "inline-block";
    }

    
    const handleKeyPress = (e) => {
        if(e.key === 'Enter' || e.key === 'Return'){
            if (e.target.value > 0) {
                e.target.style.display = "none"
                document.getElementById("focusMode").style.display = "none";
                document.getElementById("pause").style.display = "inline-block";
                document.getElementById("stop").style.display = "inline-block";
                document.getElementById("countdown").style.display = "inline-block";
                timeRemaining = e.target.value * 60
                startCountdown()
            }
        }
    }

    return (
        <div className="timer">
            <input id="enterTime"  onKeyDown={handleKeyPress} type="number" placeholder="Set timer in minutes"/>
            <label id="countdown"></label>
            {mediaButtons.map((button, index) => {
                return <button key={index} id={button.name} className="material-symbols-outlined timerButtons" onClick={handleMediaClick} style={{display: button.displayValue}}>{button.icon}</button>
            })}
            <audio id="audio" src="https://deeblaubear.com/gong.mp3"></audio>
        </div>
    )
}

