import {todo, getProjects} from '../modules/todo';
import Swal from 'sweetalert2';

export default function GetTasks({tasks, setTasks, api}) {

    const filters = [
        {
            name: "Search by label",
            query: "@YourLabelName"
        },
        {
            name: "Tasks due Today",
            query: "today"
        },
        {
            name: "Next Actions",
            query: "today &!#Nutrition Goals &!@goals | recurring & today &!#Nutrition Goals &!@goals | assigned to: me |  (/In progress & !subtask) | (/Active projects & !subtask)"
        },
        {
            name: "Work",
            query: "#M Jams & (today | (/In progress & !subtask) | (/Active projects & !subtask))"
        },
        {
            name: "Overdue Tasks",
            query: "overdue"
        }
    ];

    const taskForm = async () => {
        
        let options;      
        {filters.map((filter, index) => (
            options += `<option key='${index}' id='${index}' className="filters" value='${filter.query}' >${filter.name}</option>`
        ))}

        const projects = await getProjects(api);
        {projects.map((project, index) => (
            options += `<option key='${index}' id='${project.id}' className="projects" value='${project.name}' />`
        ))}

        const filter = await Swal.fire({
            title: "Submit or choose a predefined query",
            html: `
                <input id="filter" list="filters" name="filters" />
                <datalist id="filters">
                    ${options}
                </datalist>
            `,
            showCancelButton: true,
            confirmButtonColor: "darkgoldenrod",
            confirmButtonText: "Get Tasks",
            background: 'darkslategray',
            color: 'goldenrod',
            focusConfirm: false,
            preConfirm: () => {

                const name = document.getElementById('filter').value

                let filterQuery = projects.find((project) => project.name === name)

                if (filterQuery === undefined) {
                    filterQuery = {query: name}       
                }

                return filterQuery
            }
        });
        if(filter.isConfirmed){
            todo(setTasks, filter.value, api);
        }

    }

    return (
        <div id="getTasks">
            <button onClick={taskForm}>Get Tasks</button>
        </div>
    )

}